/* General Styles */
.about-container {
  margin: 50px auto;
  max-width: 800px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-content {
  text-align: center;
}

.about-content h1 {
  margin-bottom: 20px;
  font-size: 32px;
  color: #333;
}

.about-content p {
  margin-bottom: 20px;
  font-size: 18px;
  color: #666;
  line-height: 1.6;
}

.interactive-section h2 {
  margin-bottom: 10px;
  font-size: 28px;
  color: #333;
}

.interactive-section ul {
  list-style: none;
  padding: 0;
}

.interactive-section ul li {
  margin-bottom: 10px;
  font-size: 18px;
  color: #444;
}

.map-container {
  margin-top: 30px;
  text-align: center;
}

.map-container iframe {
  width: 100%;
  height: 450px;
  border: 0;
  border-radius: 10px;
}

.map-container .address {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

/* Team Section */
.team-section {
  margin-top: 50px;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.team-member img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles */
@media (max-width: 568px) {
  .about-container {
    padding: 10px;
  }

  .map-container iframe {
    height: 300px;
  }
}

@media (max-width: 568px) {
  .about-content h1 {
    font-size: 24px;
  }

  .about-content p {
    font-size: 16px;
  }

  .interactive-section h2 {
    font-size: 22px;
  }

  .interactive-section ul li {
    font-size: 16px;
  }

  .team-member img {
    width: 100px;
    height: 100px;
  }

  .map-container iframe {
    height: 250px;
  }
}
