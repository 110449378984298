/* src/App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.container {
  background-color: #333;
  padding: 10px;
  display:flex;
  align-items: center;
  justify-content: space-between;

  
  .logo{
    font-size: 30px;
    font-weight: bold;
    color: aliceblue;
  }


 ul {
  list-style: none;
  display: flex;
  /* flex-direction: right ; */
  float:right;
  /* align-items: flex-end; */
  /* justify-content: space-around; */
  margin-right: 20px;
  padding-right: 20px;


li {
  margin: 0;


 a {
  color: white;
  text-decoration: none;
  padding: 10px;


 a:hover {
  background-color: #575757;
}}}}}

footer {
  
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
  position: relative;
  width: 100%;
  bottom: 0;
}

div {
  padding: 20px;
}

h1 {
  color: #333;
}

img {
  max-width: 100%;
  height: auto;
}




h1, h2 {
  color: #333;
}

img {
  max-width: 100%;
  height: auto;
}

.home-container {
  margin-top: 5px;
  padding: 0;
}

.hero-banner {
  position: relative;
}

.hero-image {
  width: 100%;
  height: auto;
  opacity: 0.96;
}

.hero-text {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;

}
.tagline{
  font-size: 40px;
  color: #f4f4f4;
 }
 .tagpara{
   font-size: 20px;
 }

.services-overview {
  background-color: #fff;
  padding: 50px 20px;
  text-align: center;
}

.services { 
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap:10px;
}

.service img {
  width: 400px;
  height: 300px; 
  border-radius: 10px;
}

.service {
  flex: 1 1 calc(50% - 20px); 
  box-sizing: border-box;
}

.service img {
  width: 100%;
  height: 350px;
  border-radius: 10px;
}

@media screen and (max-width: 500px) {
  .logo {
    font-size: 22px;
  }}
@media screen and (max-width: 1024px) {
  .service {
    width: 100%;
  }

  .service img {
    height: 200px; 
  }
  .tagline{
    font-size: 15px;
   }
   .tagpara{
     font-size: 9px;
   }
   .footerfont{
    font-size: small;
   }

   .container {
    padding: 10px;
    
    .logo{
      font-size: 20px;
    }
  
   ul {
    margin-right: 5px;
    padding-right: 5px;
  }
}
}
@media screen and (max-width: 450px) {
  .container{
    padding-top: 4px;
    padding-bottom: 4px;
    height:50px;
    width: 100%;
  }
  .service {
    width: 100%;
  }
  .ul-items{
    display: flex;
    flex-direction: column; 
    font-size: small;
    padding: 1px;
  }
.hero-image{
  height: 150px;
}
.tagline{
  font-size: 10px;
 }
 .tagpara{
   font-size: 5px;
 }

.services-overview {
  width: 250px;
  padding-left: 10px;
  padding-left: 10px;
  padding-top: 15px;

  h1{
    font-size: medium;
  }

  p{
    font-size: x-small;
  }
}
.services{
  gap:10px;
}

  .service img {
    height: 100px; 
  }

   .footerfont{
    font-size: x-small;
   }

   .container {
    padding: 10px;
    
    .logo{
      font-size: 20px;
    }
  
   ul {
    margin-right: 5px;
    padding-right: 5px;
  }
}
.testimonials {

  padding-top: 30px;
  padding-left:  10px;
  padding-right:  10px;
  p{
    font-size: small;
  }
}
.call-to-action{
  p{
    font-size: small;
  }
  padding-top: 30px;
  padding-left:  10px;
  padding-right:  10px;
}

  footer {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .footerfont{
    font-size: x-small;
   }

}









.testimonials {
  background-color: #beb7b7;
  padding: 50px 20px;
  text-align: center;
}

.testimonial {
  max-width: 600px;
  margin: 0 auto;
}

.testimonial img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.call-to-action {
  background-color: #beb7b7;
  color: white;
  padding: 50px 20px;
  text-align: center;
}

.call-to-action button {
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.call-to-action button:hover {
  background-color: #ff8533;
}

